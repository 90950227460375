import React from 'react'
import styled from 'styled-components'
import { MdModeEdit } from 'react-icons/md'
import * as PriceUtils from '../../price/PriceUtils'
import * as DateUtils from '../../commons/DateUtils'
import * as NumberUtils from '../../commons/NumberUtils'
import Colors from '../../commons/Colors'
import BasicLoading from '../../commons/components/Loading'
import RoundButton from '../../commons/components/RoundButton'
import DebtorClientIndicator from '../../commons/components/DebtorClientIndicator'
import EditedOrderIndicator from '../../commons/components/EditedOrderIndicator'
import OutOfPricePolicyIndicator from '../../commons/components/OutOfPricePolicyIndicator'
import { Label } from '../../commons/commonStyles'
import { Modal } from '../../commons/components/modal'
import { STATUS_TEXT, SIMPLE_BILLING_STATUS_TEXT } from '../../commons/Status'

import PrintOrder from '../PrintOrder'
import OrderDetailsAccordionComponent from '../../order/OrderDetailsAccordionComponent'

const OrderModalView = ({
    order,
    fragments,
    shipments,
    isLoading,
    onEditClick,
    clearSelectedOrderId,
    isUseFragmentedOrder,
    isUseShipment,
    isOrderCanceled,
    isItemOutOfPricePolicy,
    isFreight,
    isCharges,
    havePalletInfo,
    needsPallet,
    haveChapaInfo,
    needsChapa,
    isEditionAllowed,
    isDebtorClient,
}) => {
    const FREIGHT_TYPE_LABEL = {
        CIF: 'CIF (com frete)',
        FOB: 'FOB (sem frete)',
        undefined: '-',
        null: '-',
    }

    const renderModalTitle = order => {
        return `${order.isUseShipment ? 'Simples Faturamento' : 'Pedido'} Nº ${
            order.orderNumber
        }`
    }

    const renderEditionIcon = order => {
        return (
            !isOrderCanceled && <EditedOrderIndicator edited={order.isEdited} />
        )
    }

    const renderIsOutOfPricePolicyIcon = (order, item) => {
        if (isItemOutOfPricePolicy(order, item)) {
            return <OutOfPricePolicyIndicator isOutOfPricePolicy />
        }
    }

    const getEditionReason = order => {
        return order?.reasonEdition
    }

    const getWeightInTonnes = weightInTonnes => {
        return `${NumberUtils.formatDecimalPlace(weightInTonnes)} ton.`
    }

    const getProductValue = price => {
        return NumberUtils.numberFormat(price, 2, 'R$')
    }

    const getUnitPrice = orderItem => {
        return NumberUtils.numberFormat(
            orderItem.price / orderItem.quantity,
            2,
            'R$'
        )
    }

    const renderOrderStatus = order => {
        const statusText =
            isUseShipment && order.statusText !== STATUS_TEXT.ENTREGUE
                ? SIMPLE_BILLING_STATUS_TEXT.PENDENTE
                : order.statusText

        return (
            <OrderStatus statusCanceled={isOrderCanceled}>
                {statusText}
            </OrderStatus>
        )
    }

    return (
        <Modal visible={true} onClose={clearSelectedOrderId}>
            <BodyModal>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <HeaderContainer
                            statusCanceled={isOrderCanceled}
                            data-testid="headerOrderModal"
                        >
                            <HeaderOrderModal>
                                <TitleModal>
                                    {renderModalTitle(order)}
                                    <TitleIcon>
                                        {renderEditionIcon(order)}
                                    </TitleIcon>
                                </TitleModal>

                                <RightComponentes>
                                    {renderOrderStatus(order)}
                                    <PrintOrder
                                        screenProps={order}
                                        fragmentedOrders={fragments}
                                    />
                                </RightComponentes>
                            </HeaderOrderModal>

                            {isOrderCanceled && (
                                <ReasonOrderCanceled>
                                    <span>Motivo do Cancelamento</span>

                                    <p>{order.reasonCancellation}</p>
                                </ReasonOrderCanceled>
                            )}

                            {getEditionReason(order) && !isOrderCanceled && (
                                <ReasonOrderEdited>
                                    <span>Motivo da Edição</span>

                                    <p>{order.reasonEdition}</p>
                                </ReasonOrderEdited>
                            )}
                        </HeaderContainer>

                        <SectionContainer>
                            <TitleContainer>
                                <SectionTitle>Detalhe do pedido</SectionTitle>
                                {isEditionAllowed && (
                                    <EditButton
                                        onClick={() => onEditClick(order._id)}
                                    >
                                        <EditIcon />
                                        Editar
                                    </EditButton>
                                )}
                            </TitleContainer>

                            <SectionOrderDetail>
                                <SectionElement>
                                    <Label>Cliente</Label>
                                    <Value>
                                        {order.farm.owner.name.toLowerCase()}
                                    </Value>
                                </SectionElement>

                                <SectionElement>
                                    <Label>Fazenda</Label>
                                    <Value>
                                        {order.farm.fancyName.toLowerCase()}
                                    </Value>
                                </SectionElement>

                                <SectionElement>
                                    <Label>Rota</Label>
                                    <Value>
                                        {order.category?.name?.toLowerCase()}
                                    </Value>
                                </SectionElement>
                            </SectionOrderDetail>

                            <SectionOrderDetail>
                                {!order.isUseShipment && (
                                    <SectionElement>
                                        <Label>Data de Entrega</Label>
                                        <DeliveryDate>
                                            {DateUtils.dateFormat(
                                                order.deliveryDate
                                            )}
                                        </DeliveryDate>
                                    </SectionElement>
                                )}

                                <SectionElement>
                                    <Label>Razão Social</Label>
                                    <Value>
                                        {order.farm.corporateName || ' - '}
                                    </Value>
                                </SectionElement>

                                <SectionElement>
                                    <Label>Código do Cliente</Label>
                                    <Value>
                                        {order.farm.legacyCode || ' - '}
                                    </Value>
                                </SectionElement>
                            </SectionOrderDetail>

                            <SectionOrderDetailTwoInfos>
                                <SectionElement>
                                    <Label>TELEFONE</Label>
                                    <Value>
                                        {order.farm.owner.phone || ' - '}
                                    </Value>
                                </SectionElement>

                                <SectionElement>
                                    <Label>ENDEREÇO DE ENTREGA</Label>
                                    <NoteValue>
                                        {order.farm.address || ' - '}
                                    </NoteValue>
                                </SectionElement>
                            </SectionOrderDetailTwoInfos>

                            <NoteSectionElement>
                                <Label>OBSERVAÇÃO DO PEDIDO</Label>
                                <NoteValue>{order.note || ' - '}</NoteValue>
                            </NoteSectionElement>

                            <MadeByContainer>
                                <MadeBy>
                                    Feito por {order.seller.name} em{' '}
                                    {DateUtils.dateFormat(order.requestDate)} -{' '}
                                    {DateUtils.timeFormat(order.requestDate)}
                                </MadeBy>
                            </MadeByContainer>
                        </SectionContainer>

                        {Boolean(order.farm.note) && (
                            <>
                                <SectionLine />

                                <SectionContainer>
                                    <SectionTitle>
                                        Observação do cliente
                                    </SectionTitle>
                                    <NoteValue>{order.farm.note}</NoteValue>
                                </SectionContainer>
                            </>
                        )}

                        <SectionLine />

                        <SectionContainer>
                            <SectionTitle>Pagamento</SectionTitle>
                            <SectionPaymentDetail>
                                <SectionElement>
                                    <Label>FORMA DE PAGAMENTO</Label>
                                    <Value>
                                        {order.paymentForm.toLowerCase()}
                                    </Value>
                                </SectionElement>
                                <SectionElement>
                                    <Label
                                        htmlFor="payment-deadline"
                                        id="paymentDeadlineLabel"
                                    >
                                        PRAZO DE PAGAMENTO
                                    </Label>
                                    <Value
                                        id="payment-deadline"
                                        aria-labelledby="paymentDeadlineLabel"
                                    >
                                        {order.paymentDate
                                            ? DateUtils.dateFormat(
                                                  order.paymentDate
                                              )
                                            : order.paymentDeadline.toLowerCase()}
                                    </Value>
                                </SectionElement>
                                {isFreight && (
                                    <SectionElement>
                                        <Label
                                            htmlFor="freight-type"
                                            id="freightTypeLabel"
                                        >
                                            TIPO DE FRETE
                                        </Label>
                                        <Value
                                            id="freight-type"
                                            aria-labelledby="freightTypeLabel"
                                        >
                                            {
                                                FREIGHT_TYPE_LABEL[
                                                    order.freightType
                                                ]
                                            }
                                        </Value>
                                    </SectionElement>
                                )}
                                {havePalletInfo && (
                                    <SectionElement>
                                        <Label
                                            htmlFor="needs-pallet"
                                            id="needsPalletLabel"
                                        >
                                            PALLET
                                        </Label>
                                        <Value
                                            id="needs-pallet"
                                            aria-labelledby="needsPalletLabel"
                                        >
                                            {needsPallet}
                                        </Value>
                                    </SectionElement>
                                )}
                                {haveChapaInfo && (
                                    <SectionElement>
                                        <Label
                                            htmlFor="needs-chapa"
                                            id="needsChapaLabel"
                                        >
                                            CHAPA
                                        </Label>
                                        <Value
                                            id="needs-chapa"
                                            aria-labelledby="needsChapaLabel"
                                        >
                                            {needsChapa}
                                        </Value>
                                    </SectionElement>
                                )}
                                {isDebtorClient && (
                                    <DebtorClientIndicator
                                        debtor={isDebtorClient}
                                    />
                                )}
                            </SectionPaymentDetail>
                        </SectionContainer>

                        <SectionLine />

                        <SectionContainer>
                            <SectionTitle>
                                Produtos ({order.items.length})
                            </SectionTitle>

                            <div>
                                {order.items.map(item => (
                                    <ProductContainer key={item._id}>
                                        <ProductValue>
                                            {item.product.legacyCode}
                                        </ProductValue>
                                        <InfoContainer>
                                            <ProductName>
                                                {item.product.name.toLowerCase()}{' '}
                                                -{' '}
                                                {
                                                    item.product.measurementUnit
                                                        .weight
                                                }
                                            </ProductName>
                                            <ProductInfo>
                                                {item.unQuantity} /{' '}
                                                {getUnitPrice(item)} por{' '}
                                                {
                                                    item.product.measurementUnit
                                                        .measure
                                                }
                                            </ProductInfo>
                                        </InfoContainer>
                                        <PriceAndIconContainer>
                                            <ProductValue>
                                                {getProductValue(item.price)}
                                            </ProductValue>
                                            {renderIsOutOfPricePolicyIcon(
                                                order,
                                                item
                                            )}
                                        </PriceAndIconContainer>
                                    </ProductContainer>
                                ))}
                            </div>

                            <TotalWeight>
                                <TotalWeightLabel>
                                    {'Peso total (toneladas): '}
                                </TotalWeightLabel>
                                {getWeightInTonnes(order.weightInTonnes)}
                            </TotalWeight>

                            <SectionLineTable />

                            {isUseFragmentedOrder && (
                                <div>
                                    <div>
                                        <OrderDetailsAccordionComponent
                                            shippings={fragments}
                                            order={order}
                                        />
                                    </div>

                                    <SectionLineTable />
                                </div>
                            )}

                            {isUseShipment && shipments.length > 0 && (
                                <div>
                                    <div>
                                        <OrderDetailsAccordionComponent
                                            shippings={shipments}
                                            order={order}
                                        />
                                    </div>

                                    <SectionLineTable />
                                </div>
                            )}

                            <TotalContainer>
                                <TotalContainerLeftColumn>
                                    {(isFreight || isCharges) && (
                                        <TxtPreTotal>Subtotal</TxtPreTotal>
                                    )}

                                    {isFreight && (
                                        <TxtPreTotal>Frete</TxtPreTotal>
                                    )}
                                    {isCharges && (
                                        <TxtPreTotal>Encargos</TxtPreTotal>
                                    )}
                                    <TxtTotal>TOTAL DO PEDIDO</TxtTotal>
                                </TotalContainerLeftColumn>
                                <TotalContainerRightColumn>
                                    {(isFreight || isCharges) && (
                                        <PreTotalValue>
                                            {PriceUtils?.getPriceString(
                                                order?.subtotal || 0
                                            )}
                                        </PreTotalValue>
                                    )}

                                    {isFreight && (
                                        <PreTotalValue>
                                            {PriceUtils.getPriceString(
                                                order?.freightPrice || 0
                                            )}
                                        </PreTotalValue>
                                    )}
                                    {isCharges && (
                                        <PreTotalValue>
                                            {PriceUtils.getPriceString(
                                                order?.chargesPrice || 0
                                            )}
                                        </PreTotalValue>
                                    )}

                                    <TotalValue>
                                        {PriceUtils.getPriceString(order.price)}
                                    </TotalValue>
                                </TotalContainerRightColumn>
                            </TotalContainer>
                        </SectionContainer>
                    </>
                )}
            </BodyModal>
        </Modal>
    )
}

const BodyModal = styled.div`
    width: 45vw;
    min-width: 350px;
    min-height: 300px;
    box-sizing: content-box;
    max-height: 90vh;
    overflow: auto;
`

const Loading = styled(BasicLoading)`
    height: 300px;
`

const HeaderOrderModal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
`

const ReasonOrderEdited = styled.div`
    margin-right: 17px;

    span {
        color: grey;
        font-weight: bold;
        font-size: 14px;
    }

    p {
        color: grey;
        font-size: 12px;
    }
`

const ReasonOrderCanceled = styled.div`
    margin-right: 17px;

    span {
        font-weight: bold;
        color: ${Colors.black};
    }

    p {
        font-size: 14px;
        color: ${Colors.black};
    }
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props =>
        props.statusCanceled ? '#c7c7cc' : 'rgba(241, 141, 58, 0.15)'};
    padding: 0 17px;
`

const SectionContainer = styled.div`
    margin: 0 17px;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const EditIcon = styled(MdModeEdit)`
    display: flex;
    align-self: center;
`

const EditButton = styled(RoundButton)`
    display: flex;
    align-items: center;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    line-height: 1.71;
    width: fit-content;
    align-self: center;
    gap: 10px;
`

const TitleModal = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: ${Colors.deepSeaBlue};
    display: flex;
`

const TitleIcon = styled.div`
    margin-left: 10px;
`

const OrderStatus = styled.span`
    height: 20px;
    border-radius: 5px;
    background-color: ${props =>
        props.statusCanceled ? '#333333' : Colors.sienna};
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.white};
    padding: 0 20px;
`

const SectionLine = styled.hr`
    margin-block-start: 0;
    margin-block-end: 0;
    height: 8px;
    background-color: ${Colors.veryLightPink};
    border-width: 0;
    margin-top: 9px;
`

const SectionLineTable = styled.hr`
    margin-block-start: 0;
    margin-block-end: 0;
    height: 1px;
    background-color: ${Colors.lightBlueGrey};
    border-width: 0;
    margin-top: 9px;
    margin-bottom: 13px;
`

const SectionTitle = styled.h2`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: ${Colors.black};
    /* margin-left: 17px; */
`

const SectionElement = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: lowercase;
`

const Value = styled.span`
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.black};
    text-transform: capitalize;
`

const DeliveryDate = styled(Value)`
    color: ${Colors.bluishGreen};
`

const SectionOrderDetail = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 17px 0;
`

const SectionOrderDetailTwoInfos = styled(SectionOrderDetail)`
    grid-template-columns: 1fr 2fr;
`

const NoteSectionElement = styled(SectionElement)``

const NoteValue = styled(Value)`
    text-transform: none;
`

const MadeByContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;
`

const MadeBy = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 0.21px;
    color: ${Colors.brownishGrey};
`

const SectionPaymentDetail = styled.div`
    display: grid;
    grid-template-columns: repeat(5, auto);
    /* margin: 17px; */
`

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 13px;
    border-bottom: solid 1px ${Colors.lightBlueGreyTwo};

    &:last-child {
        border-bottom: none;
    }
`

const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
`

const PriceAndIconContainer = styled.div`
    height: 45px;
    text-align: right;
    width: 25%;
`

const ProductInfo = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
    text-transform: capitalize;
    margin-top: 6px;
`

const ProductName = styled(ProductInfo)`
    font-weight: bold;
    color: ${Colors.black};
`

const ProductValue = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: 0.13px;
    color: ${Colors.black};
    width: 15%;
    overflow-wrap: break-word;
`

const TotalWeight = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
    margin-top: 6px;
`

const TotalWeightLabel = styled(TotalWeight)`
    font-weight: bold;
    color: ${Colors.black};
    margin: 0;
`

const TotalContainer = styled.div`
    font-family: Roboto;
    display: flex;
    padding-bottom: 15px;
    justify-content: space-between;
`

const TotalContainerLeftColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const TotalContainerRightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const TxtPreTotal = styled.span`
    font-size: 14px;
    letter-spacing: 0.44px;
    color: ${Colors.blueGrey};
    margin-bottom: 10px;
`

const PreTotalValue = styled.span`
    font-size: 14px;
    letter-spacing: 0.29px;
    text-align: right;
    color: ${Colors.blueGrey};
    margin-bottom: 10px;
`

const TxtTotal = styled.span`
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.44px;
    color: ${Colors.brownishGrey};
    margin-top: 5px;
`

const TotalValue = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.29px;
    text-align: right;
    color: ${Colors.bluishGreen};
    margin-top: 5px;
`

const RightComponentes = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 15px;
    align-items: center;
`

export default OrderModalView
