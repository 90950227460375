import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import * as ClientAPI from '../ClientAPI'
import * as ClientPortfolioAPI from '../../portfolio/ClientPortfolioAPI'
import * as FreightTableAPI from '../../order/FreightTableAPI'
import * as PromiseUtils from '../../commons/PromiseUtils'
import ClientFormView from './ClientFormView'

import { useMillContext } from '../../context/MillContext'
import { MessageType, addMessage } from '../../commons/MessageUtils'

export const ClientFormContainer = () => {
    const requestStatusInitialValue = {
        clients: { isError: false, error: '' },
        portfolios: { isError: false, error: '' },
    }
    const { id } = useParams()
    const { mill } = useMillContext()

    const [client, setClient] = useState({})
    const [, setRequestStatus] = useState(requestStatusInitialValue)

    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState()
    const [selectedCategories, setSelectedCategories] = useState([])
    const [isEdition, setIsEdition] = useState(false)
    const [freightTables, setFreightTables] = useState([])
    const [clientId, setClientId] = useState(id)
    const [emptyClient] = useState({
        legacyCode: '',
        tradeName: '',
        companyName: '',
        owner: {
            name: '',
            birthDate: null,
            phone: '',
            millId: mill.gcpId,
        },
        address: '',
        addressReference: '',
        stockSafetyDays: '',
        debtor: false,
        note: '',
        icmsAliquot: '',
        freightRate: '',
        freightTableId: null,
        millId: mill.gcpId,
        millSydleId: null,
        portfolios: [],
    })
    const [emptyCategory] = useState({
        name: '',
        seller: { _id: '' },
        allSuggestions: [],
        isInvalid: false,
    })
    const [form, setForm] = useState({})
    const [formCategories, setFormCategories] = useState([])
    const [sellerDropdownOptions, setSellerDropdownOptions] = useState([])
    const [sendingToAPI, setSendingToAPI] = useState(false)
    const [invalidFields, setInvalidFields] = useState({
        legacyCode: false,
        ownerName: false,
        tradeName: false,
    })
    const [isLoadingSellers, setIsLoadingSellers] = useState(true)
    const history = useHistory()
    const { isUseTributes } = useMillContext()

    useEffect(() => {
        const fetchClients = async clientId => {
            const response = await ClientAPI.getClient(clientId)
            if (response && response.data) {
                if (response.data.owner && response.data.owner.birthDate) {
                    response.data.owner.birthDate = new Date(
                        response.data.owner.birthDate
                    )
                }
                setClient(response.data)
            }
        }
        if (clientId) {
            fetchClients(clientId)
            setIsEdition(true)
        } else {
            setClient(emptyClient)
        }
    }, [clientId, emptyCategory, emptyClient])

    useEffect(() => {
        const fetchFreightTables = async () => {
            try {
                const response = await FreightTableAPI.getFreightTablesV1()
                if (response && response.data && Array.isArray(response.data)) {
                    setFreightTables(response.data)
                }
            } catch (err) {
                console.error(err)
            }
        }
        fetchFreightTables()
    }, [])

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await ClientAPI.getPortfolios(mill.gcpId)
            if (response && response.data && response.data instanceof Array) {
                setCategories(response.data)
            }
        }
        fetchCategories()
    }, [mill])

    useEffect(() => {
        setForm(client)
    }, [client])

    useEffect(() => {
        if (client && client.id && categories.length > 0) {
            const clientCategories = categories.filter(category =>
                client.portfolios.find(
                    portfolio => portfolio.id === category.id
                )
            )
            setSelectedCategories(clientCategories)
        }
    }, [client, categories])

    useEffect(() => {
        setFormCategories(
            categories.map(category => ({
                label: category.name,
                value: category.id,
            }))
        )
        setIsLoadingSellers(false)
    }, [categories])

    useEffect(() => {
        const newFormCategories = categories
            .filter(cat => !selectedCategories.find(c => c.id === cat.id))
            .map(category => ({ label: category.name, value: category.id }))
        setFormCategories(newFormCategories)
        setSellerDropdownOptions([])
    }, [selectedCategories, categories])

    // TODO - UNIFICAR CONTROLE DE INPUT
    const handleClientChange = (e, valueField = 'value') => {
        setForm({
            ...form,
            [e.target.name]: e.target[valueField],
        })
    }

    const handleTributesChange = e => {
        setForm({
            ...form,
            tributes: {
                ...form.tributes,
                [e.target.name]: e.target.value,
            },
        })
    }

    const handleOwnerChange = e => {
        setForm({
            ...form,
            owner: {
                ...form.owner,
                [e.target.name]: e.target.value,
            },
        })
    }

    const handleCategoryChange = e => {
        setSelectedCategory(e.target.value)
        const category = categories.find(cat => cat.id === e.target.value)
        if (category)
            setSellerDropdownOptions(
                category.users.map(user => ({
                    label: user.name,
                    value: user.id,
                }))
            )
        else setSellerDropdownOptions([])
    }

    const handleOnPressSave = e => {
        e.preventDefault()
        if (existsInvalidFields(form)) {
            const errorMessage =
                'Erro, existem campos obrigatórios sem preenchimento ou preenchidos de forma incorreta.'

            addMessage(MessageType.ERROR, errorMessage)
            setInvalidFields({
                legacyCode: !form.legacyCode,
                ownerName: !form.owner.name,
                tradeName: !form.tradeName,
            })
        } else {
            onSaveClient(form)
        }
    }

    const mountPortfoliosToSaveClient = clientIdToHandle => {
        const portfoliosToAdd = selectedCategories.reduce(
            (acc, selectedCategory) => {
                const isNewClientOnPortfolio = !selectedCategory.clients.some(
                    client => client.id === clientId
                )
                if (isNewClientOnPortfolio) {
                    return [
                        ...acc,
                        { operation: 'ADD', portfolio: selectedCategory },
                    ]
                }

                return acc
            },
            []
        )

        const portfoliosToRemove = client.portfolios.reduce(
            (acc, portfolio) => {
                const isClientRemovedFromportfolio = !selectedCategories.some(
                    selectedCategory => selectedCategory.id === portfolio.id
                )

                if (isClientRemovedFromportfolio) {
                    return [...acc, { operation: 'REMOVE', portfolio }]
                }

                return acc
            },
            []
        )

        const portfoliosToUpdate = [
            ...portfoliosToAdd,
            ...portfoliosToRemove,
        ].reduce((acc, portfolioToHandle) => {
            const portfolio = categories.find(
                category => portfolioToHandle.portfolio.id === category.id
            )

            const baseportfolioToUpdate = {
                clientPortfolioId: portfolio.id,
                name: portfolio.name,
                users: {
                    add: [],
                    remove: [],
                },
            }

            if (portfolioToHandle.operation === 'ADD') {
                const updatedPortfolio = {
                    ...baseportfolioToUpdate,
                    clients: {
                        add: [clientIdToHandle],
                        remove: [],
                    },
                }
                return [...acc, updatedPortfolio]
            }

            const updatedPortfolio = {
                ...baseportfolioToUpdate,
                clients: {
                    add: [],
                    remove: portfolio.clients
                        .filter(
                            categoryClient =>
                                categoryClient.id === clientIdToHandle
                        )
                        .map(categoryClient => categoryClient.id),
                },
            }

            return [...acc, updatedPortfolio]
        }, [])

        return portfoliosToUpdate
    }

    const sendAllPortfoliosUpdate = async portfoliosToUpdate => {
        if (!portfoliosToUpdate.length) return

        return await PromiseUtils.PromiseWrapper({
            promise: ClientPortfolioAPI.updatePortfolio(portfoliosToUpdate),
            onRejected: () => {
                setRequestStatus(requestStatus => ({
                    ...requestStatus,
                    portfolios: {
                        isError: true,
                        error: `O cliente ${client.owner.name} não pode estar associado a mais de uma rota com o mesmo vendedor.`,
                    },
                }))
            },
            onFulfilled: () => {
                setRequestStatus(requestStatus => ({
                    ...requestStatus,
                    portfolios: {
                        isError: false,
                        error: '',
                    },
                }))
            },
        })
    }

    const sendClient = async (clientToSave, clientId) => {
        let createdClientId
        // TODO - Melhorar forma de garantir que o update de client não realiza atualização ao receber portfolios do front.
        await PromiseUtils.PromiseWrapper({
            promise: clientId
                ? await ClientAPI.updateClient(
                      { ...clientToSave, portfolios: undefined },
                      clientId
                  )
                : await ClientAPI.createClient({
                      ...clientToSave,
                      portfolios: undefined,
                  }),
            onFulfilled: ({ data }) => {
                if (!clientId) createdClientId = data.id

                setRequestStatus(requestStatus => ({
                    ...requestStatus,
                    clients: {
                        isError: false,
                        error: '',
                    },
                }))
            },
            onRejected: () => {
                setRequestStatus(requestStatus => ({
                    ...requestStatus,
                    clients: {
                        isError: true,
                        error: 'Erro ao salvar cliente.',
                    },
                }))
            },
        })

        let currentClientId = createdClientId ? createdClientId : clientId
        if (createdClientId) setClientId(currentClientId)

        return currentClientId
    }

    const onSaveClient = async client => {
        setRequestStatus(requestStatusInitialValue)
        try {
            setSendingToAPI(true)

            const clientIdToMount = await sendClient(client, clientId)
            const portfoliosToSend =
                mountPortfoliosToSaveClient(clientIdToMount)

            await sendAllPortfoliosUpdate(portfoliosToSend)
        } catch (err) {
            console.error(err)
            setSendingToAPI(false)
        } finally {
            setSendingToAPI(false)
            setRequestStatus(requestStatus => {
                const { clients, portfolios } = requestStatus
                if (!clients.isError && !portfolios.isError) {
                    addMessage(
                        MessageType.SUCCESS,
                        'Cliente e carteira(s) salvo(s) com sucesso!'
                    )
                    history.push('/clientes')
                    return requestStatus
                }

                if (clients.isError && portfolios.isError) {
                    addMessage(
                        MessageType.ERROR,
                        'Erro ao salvar cliente e carteira(s).'
                    )
                    return requestStatus
                }

                if (!clients.isError && portfolios.isError) {
                    addMessage(MessageType.ERROR, portfolios.error)
                    return requestStatus
                }

                if (clients.isError && !portfolios.isError) {
                    addMessage(MessageType.ERROR, clients.error)
                    return requestStatus
                }

                return requestStatus
            })
        }
    }

    const existsInvalidFields = client => {
        const validation =
            !client.legacyCode || !client.owner.name || !client.tradeName

        return validation
    }

    const getFreigthTable = () => {
        return freightTables.map(freigthTable => {
            return {
                label: freigthTable.region,
                value: freigthTable.id,
            }
        })
    }

    const handleSelectedCategories = e => {
        e.preventDefault()
        const categorySelected = categories.find(
            cat => selectedCategory === cat.id
        )

        setSelectedCategories(previousValue => [
            ...previousValue,
            categorySelected,
        ])
        setSelectedCategory(undefined)
    }

    const handleDeleteAssociated = (e, index) => {
        e.preventDefault()
        setSelectedCategories(previousValue =>
            previousValue.filter(cat => cat.id !== selectedCategories[index].id)
        )
    }

    return (
        <ClientFormView
            isEdition={isEdition}
            isLoadingSellers={isLoadingSellers}
            isUseTributes={isUseTributes}
            form={form}
            formCategories={formCategories}
            setFormCategories={setFormCategories}
            sellerDropdownOptions={sellerDropdownOptions}
            sendingToAPI={sendingToAPI}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            handleClientChange={handleClientChange}
            handleTributesChange={handleTributesChange}
            handleOwnerChange={handleOwnerChange}
            handleCategoryChange={handleCategoryChange}
            handleOnPressSave={handleOnPressSave}
            getFreigthTable={getFreigthTable}
            categories={categories}
            handleSelectedCategories={handleSelectedCategories}
            selectedCategory={selectedCategory}
            selectedCategories={selectedCategories}
            handleDeleteAssociated={handleDeleteAssociated}
        />
    )
}

export default ClientFormContainer
