import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdDone } from 'react-icons/md'

import Colors from '../../commons/Colors'
import RoundButton from '../../commons/components/RoundButton'
import ClientInformation from '../ClientInformation'
import OrderItems from '../OrderItems'
import DeliveryAndSellerInformation from '../DeliveryAndSellerInformation'
import PaymentFormsComponent from '../PaymentForms'
import FreightOptionsComponent from '../FreightOptionsComponent'

import OrderOverview from '../OrderOverview'
import Loading from '../../commons/components/Loading'
import BasicPage from '../../commons/components/BasicPage'
import BasicFormScreen from '../../commons/components/BasicFormScreen'
import { Label } from '../../commons/commonStyles'
import InputTextArea from '../../commons/components/InputTextarea'
import OrderEditionBasicInformation from '../OrderEditionBasicInformation'
import StockSafetyDaysModal from '../StockSafetyDaysModal'
import { getSellerRates } from '../OrderFormScreenUtils'
import PalletOptionsComponent from '../PalletOptionsComponent'
import ChapaOptionsComponent from '../ChapaOptionsComponent'

const OrderFormView = ({
    loadingForm,
    setOrder,
    order,
    isMillHasFreight,
    millConfig,
    mill,
    freightTable,
    isSavingOrder,
    onSaveOrder,
    invalidFieldsHandler,
    isNewSimpleBilling,
}) => {
    const [stockSafetyDaysModalVisible, setStockSafetyDaysModalVisible] =
        useState({ isVisible: false, rendered: false })

    useEffect(() => {
        if (order.farm?.stockSafetyDays <= 0) {
            setStockSafetyDaysModalVisible(visibilityStatus =>
                getVisibilityStatus(visibilityStatus)
            )
        }
    }, [order])

    const getVisibilityStatus = visibilityStatus => {
        return visibilityStatus.rendered
            ? visibilityStatus
            : { isVisible: true, rendered: false }
    }

    return (
        <BasicPage
            title={
                isNewSimpleBilling()
                    ? 'Novo Simples Faturamento'
                    : 'Novo Pedido'
            }
            editionTitle={'Editar Pedido'}
            isEdition={order.isOrderEdition}
        >
            <BasicFormScreen
                linkTo={() => {
                    if (isNewSimpleBilling())
                        return '/home/simples-faturamentos'
                    if (order.orderFrom?.isConfirmedOrder)
                        return '/pedidos/historico'
                    return '/pedidos'
                }}
            >
                {loadingForm ? (
                    <Loading data-testid="form-loading" />
                ) : (
                    <Form>
                        <ClientInformation
                            setClientInformation={setOrder}
                            farm={order.farm}
                            seller={order.seller}
                            isConfirmedOrder={order.orderFrom.isConfirmedOrder}
                            invalidFieldsHandler={invalidFieldsHandler}
                        />

                        {order.orderFrom.isConfirmedOrder && (
                            <OrderEditionBasicInformation
                                order={order}
                                setEditNote={setOrder}
                            />
                        )}

                        <Panel>
                            <DeliveryAndSellerInformation
                                mill={mill}
                                farm={order.farm}
                                seller={order.seller}
                                deliveryDate={order.deliveryDate}
                                isEdition={order.isOrderEdition}
                                setDeliveryAndSellerInformation={setOrder}
                                isConfirmedOrder={
                                    order.orderFrom.isConfirmedOrder
                                }
                                invalidFieldsHandler={invalidFieldsHandler}
                                isNewSimpleBilling={isNewSimpleBilling}
                            />
                        </Panel>

                        <FourColumnsContainer>
                            <PaymentFormsComponent
                                order={order}
                                setPaymentForms={setOrder}
                                invalidFieldsHandler={invalidFieldsHandler}
                            />

                            {isMillHasFreight && (
                                <FreightOptionsComponent
                                    setFreightType={setOrder}
                                    freightType={order.freightType}
                                    invalidFieldsHandler={invalidFieldsHandler}
                                />
                            )}

                            {millConfig?.usePallet && (
                                <PalletOptionsComponent
                                    setNeedsPallet={setOrder}
                                    needsPallet={order.needsPallet}
                                />
                            )}

                            {millConfig?.useChapa && isMillHasFreight && (
                                <ChapaOptionsComponent
                                    setNeedsChapa={setOrder}
                                    needsChapa={order.needsChapa}
                                    disabled={order.freightType !== 'CIF'}
                                />
                            )}
                        </FourColumnsContainer>

                        <Panel>
                            <OrderItems
                                order={order}
                                setOrder={setOrder}
                                orderItems={order.items}
                                sellerRates={getSellerRates(order)}
                                tributes={order.tributes}
                                freightTable={freightTable}
                            />
                        </Panel>

                        <Panel>
                            <Label htmlFor="note">Observação do pedido</Label>
                            <InputTextArea
                                id="note"
                                name="note"
                                placeholder="Digite alguma observação a ser adicionada ao pedido"
                                value={order.note}
                                rows={2}
                                onChange={e => setOrder('note', e.target.value)}
                            />
                        </Panel>

                        <Panel>
                            <OrderOverview order={order} />
                        </Panel>

                        <FooterContainer>
                            <RoundButton
                                loading={isSavingOrder}
                                onClick={onSaveOrder}
                            >
                                FAZER PEDIDO <MdDone />
                            </RoundButton>
                        </FooterContainer>
                    </Form>
                )}
                <StockSafetyDaysModal
                    visibilityStatus={stockSafetyDaysModalVisible}
                    onClose={setStockSafetyDaysModalVisible}
                    farm={order.farm}
                />
            </BasicFormScreen>
        </BasicPage>
    )
}

export default OrderFormView

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
    padding: 21px;
`

const Form = styled.form`
    display: grid;
    grid-row-gap: 1.68vh;
`

const FourColumnsContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
    padding: 21px;
    gap: 30px;
`

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`
